import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import TitleCard from './TitleCard';
import Container from './Container';

const CompanyPhoto = styled.div`
  background: url(${props => props.src});
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  @media (max-width: 770px) {
    height: 20rem;
  }
`;

const CompanyDescription = styled.div`
  padding: 1rem 15px;
  margin: 0;
  flex: 0 0 35rem;

  @media (max-width: 770px) {
    flex: 1;
  }
`;

const AboutContent = styled.div`
  flex-direction: row;

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

const Tagline = styled.p`
  font-size: 2rem;
  text-align: center;
  padding: 1.8rem 0;
  margin: 0;
  color: #edf2f7;

  & ::before {
    content: open-quote;
  }
  & ::after {
    content: close-quote;
  }

  && ::before,
  ::after {
    font-size: 3rem;
  }
`;

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      companyphoto: file(relativePath: { eq: "office-3.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <section id="about">
      <Tagline className="bg-blue-600">
        Recycle takes little effort on your part, for a
        <span className="font-semibold"> big difference to our world</span>
      </Tagline>

      <AboutContent className="flex bg-white">
        <CompanyPhoto src={data.companyphoto.publicURL} />
        <CompanyDescription className="text-justify text-gray-800 text-md font-medium mx-auto">
          <TitleCard
            title="About metals valley"
            marginTop={-50}
            maxWidth={20}
            fontSize="2xl"
          />
          <br />
          Metals Valley is one of the fastest growing metal trading companies in
          the Middle East. We are dealing primarily with all the various grades
          of non-ferrous scrap metals like copper, brass, aluminum, lead, zinc,
          stainless steel, etc., from Middle East and Africa.
          <br />
          {`In addition, the company is also handling finished products
          such as secondary aluminum ingots, brass ingots, copper ingots and
          lead ingots. We undoubtedly give paramount importance to our valuable
          customers and business partners to powerfully build a long-term and
          trusting relationship.`}
          <br />
          {`We genuinely care to steadfastly maintain our good reputation,
          typically equipped with uncompromising integrity, fair trading
          practices, excellent after sales service with a wholehearted
          commitment of what we are diligently doing. The company is efficiently
          managed and operated reliably by an organized group of professionals
          with more than 15 years of extensive experience in the metal trading
          industry.`}
        </CompanyDescription>
      </AboutContent>
      <Container>
        <div className="my-16 border-t-2 border-blue-600">
          <div className="flex flex-wrap">
            <div className="w-full md:w-2/4 py-6 px-8 text-center">
              <h2 className="text-3xl mb-5 text-gray-700">OUR MISSION</h2>
              <p className="text-lg">
                To support environmental sustainability by supplying quality
                controlled scrap metals to our customers thru effective
                sourcing, efficient recycling operations and processing.
              </p>
            </div>
            <div className="w-full md:w-2/4 py-6 px-8 text-center">
              <h2 className="text-3xl mb-5 text-gray-700">OUR VISION</h2>
              <p className="text-lg">
                Our vision to become one of the reliable, trusted and dependable
                business partner in recycling industry in the Middle East.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default About;
