import React from 'react';
import styled from 'styled-components';
import { Animated } from 'react-animated-css';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { graphql, useStaticQuery } from 'gatsby';

const Title = styled.p`
  font-size: 5rem;
  margin: 0;
  line-height: 1;
  letter-spacing: 1.5rem;
  text-align: center;
  word-break: normal;

  @media (max-width: 770px) {
    letter-spacing: 0.6rem;
  }
`;

const Row = styled.div`
  margin-top: 32vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(49, 130, 206, 0.8);
  color: #f9f9f9;
  padding: 2rem 0;
`;

const HomeSection = styled.section`
  overflow: hidden;
  background-image: url(${props => props.bg});
  background-position: center top;
  height: 100vh;
  width: 100%;
  position: relative;
`;

const GoDownContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 3.5rem;
`;

const GoDown = styled.a`
  display: inline-block;
  color: #fff;
  background: rgba(49, 130, 206, 0.9);
  border-radius: 50%;
  padding: 10px;
  color: #f9f9f9;
  transition: all 100ms ease-in;

  & :hover {
    transform: scale(1.1);
  }
  & svg {
    font-size: 3rem;
  }
`;

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "bg.jpg" }) {
        publicURL
      }
    }
  `);

  return (
    <HomeSection bg={data.bg.publicURL}>
      <Animated animationIn="fadeInDown" animationInDuration={1300}>
        <Row>
          <Title>RECYCLE WITH US</Title>
        </Row>
      </Animated>
      <GoDownContainer>
        <Animated animationIn="fadeInDown" animationInDuration={1900}>
          <GoDown href="#about">
            <FaAngleDoubleDown />
          </GoDown>
        </Animated>
      </GoDownContainer>
    </HomeSection>
  );
};

export default Home;
